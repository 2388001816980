import React, {useEffect, useState} from "react";
import {Link, useNavigate} from "react-router-dom";
import {decodeToken} from "react-jwt";
import {
    Box,
    Button,
    Card,
    CircularProgress,
    InputLabel,
    OutlinedInput,
    Stack,
    Typography,
} from "@mui/material";
import ArrowForwardSharpIcon from "@mui/icons-material/ArrowForwardSharp";
import api from "~/api";
import useUser from "~/providers/user/useUser";
import {useDialog} from "~/providers/dialog";
import Logo from "~/assets/logo.svg";
import Background from "~/assets/loginBackground.png";

const Login = () => {
    const [user, saveUser] = useUser();
    const [loading, setLoading] = useState(false);
    const [infos, setInfoss] = useState({
        username: "",
        password: "",
    });
    const showDialog = useDialog();
    const navigate = useNavigate();

    useEffect(() => {
        if (user) {
            navigate("/");
        }
    }, [user, navigate]);

    const handleLogin = async (e) => {
        e.preventDefault();
        if (loading) {
            return;
        }
        if (infos.username.length === 0 && infos.password.length === 0) {
            showDialog("Atenção", "Preencha o usuário e senha.");
            return;
        }

        setLoading(true);
        try {
            const result = await api.auth.login(infos.username, infos.password);
            if (result) {
                const payload = decodeToken(result.token);
                saveUser({
                    ...result,
                    ...payload,
                });
            }
        } catch (e) {
            showDialog("Não foi possível efetuar login.", e);
        }
        setLoading(false);
    };

    const handleChange = (event) => {
        const {name, value} = event.target;
        setInfoss({
            ...infos,
            [name]: value,
        });
    };

    return (
        <Box
            sx={{
                backgroundColor: "#F4F6F8",
            }}
        >
            <Box
                sx={{
                    background: `url(${Background})`,
                    height: "100vh",
                    top: "-85px",
                    left: "-305px",
                    opacity: "0.3px",
                    backgroundRepeat: "no-repeat",
                }}
            >
                <Box
                    sx={{
                        flexDirection: "column",
                        display: "flex",
                        height: "100vh",
                        width: "100vw",
                        alignItems: "center",
                    }}
                >
                    <Box
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            height: {lg: "30vh", md: "25vh", sm: "20vh", xs: "10vh"},
                        }}
                    >
                        <img src={Logo} alt="Voxit logo" width={194}/>
                    </Box>
                    <Card
                        sx={{
                            width: {lg: "35vw", md: "70vw", sm: "80vw", xs: "95vw"},
                            heigth: "100%",
                            height: {lg: "70vh", md: "75vh", sm: "80vh", xs: "90vh"},
                            padding: {lg: "60px", md: "55px", sm: "45px", xs: "30px"},
                            boxShadow: "0 4px 10px 2px rgba(255,255,255,0.3)",
                            borderRadius: "29px 29px 0 0 ",
                            borderColor: "#f1f1f1",
                            border: "1px solid #f1f1f1",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            flex: 1,
                        }}
                    >
                        <Box
                            sx={{
                                height: "100%",
                                width: "100%",
                            }}
                        >
                            <Typography
                                fontWeight={"700"}
                                align="center"
                                sx={{
                                    fontSize: {lg: "35px", md: "30px", sm: "25px", xs: "25px"},
                                }}
                                className="heading"
                                fontStyle="normal"
                                lineHeight="normal"
                                fontFamily={"Open Sans"}
                            >
                                Login
                            </Typography>
                            <form onSubmit={handleLogin}>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignContent: "space-around",
                                        margin: "5px 0 5px 0",
                                    }}
                                >
                                    <Stack className="email-input">
                                        <InputLabel
                                            sx={{
                                                fontFamily: "Open Sans",
                                                fontStyle: "normal",
                                                fontSize: {
                                                    lg: "20px",
                                                    md: "20px",
                                                    sm: "15px",
                                                    xs: "15px",
                                                },
                                                fontWeight: 400,
                                                margin: "10px 0 10px 0",
                                                lineHeight: "normal",
                                            }}
                                            htmlFor="email-login"
                                        >
                                            E-mail
                                        </InputLabel>
                                        <OutlinedInput
                                            id="email-login"
                                            type="email"
                                            value={infos.username}
                                            name="username"
                                            required
                                            onChange={handleChange}
                                            fullWidth
                                        />
                                    </Stack>
                                    <Stack>
                                        <InputLabel
                                            sx={{
                                                fontFamily: "Open Sans",
                                                fontStyle: "normal",
                                                fontSize: {
                                                    lg: "20px",
                                                    md: "20px",
                                                    sm: "15px",
                                                    xs: "15px",
                                                },
                                                fontWeight: 400,
                                                lineHeight: "normal",
                                                margin: "10px 0 10px 0",
                                            }}
                                            htmlFor="password-login"
                                        >
                                            Senha
                                        </InputLabel>
                                        <OutlinedInput
                                            fullWidth
                                            required
                                            type={"password"}
                                            value={infos.password}
                                            name="password"
                                            onChange={handleChange}
                                        />
                                    </Stack>
                                </Box>
                                <Box sx={{display: "flex", flexDirection: "row-reverse"}}>
                                    <Button
                                        flexDirection="row-reverse"
                                        sx={{
                                            color: "#6B3EFF",
                                            textTransform: "none",
                                            padding: 0,
                                            fontFamily: "Open Sans",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            lineHeight: "normal",
                                            fontSize: 15,
                                        }}
                                        component={Link}
                                        to={"/recover"}
                                    >
                                        Esqueci minha senha
                                    </Button>
                                </Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "column",
                                        alignItems: "center",
                                    }}
                                >
                                    <Button
                                        type={!loading && "submit"}
                                        sx={{
                                            textTransform: "capitalize",
                                            borderRadius: "29px",
                                            background: "#6B3EFF",
                                            fontSize: 15,
                                            fontFamily: "Open Sans",
                                            fontStyle: "normal",
                                            fontWeight: 400,
                                            width: {lg: "60%", md: "80%", sm: "85%", xs: "90%"},
                                            ":hover": {
                                                backgroundColor: "#6B3EFF",
                                            },
                                            margin: "50px 0 15px 0",
                                            height: "50px",
                                        }}
                                        loading={loading}
                                        size="large"
                                        variant="contained"
                                    >
                                        {loading ? (
                                            <CircularProgress color="white"/>
                                        ) : (
                                            <>Entrar {<ArrowForwardSharpIcon/>}</>
                                        )}
                                    </Button>

                                    <Button
                                        item
                                        xs={8}
                                        component={Link}
                                        sx={{
                                            textTransform: "none",
                                            borderColor: "#6B3EFF",
                                            borderRadius: "29px",
                                            mt: 1,
                                            mb: 1,
                                            width: {lg: "60%", md: "80%", sm: "85%", xs: "90%"},
                                            color: "#6B3EFF",
                                            fontSize: 15,
                                            fontFamily: "Open Sans",
                                            fontStyle: "normal",
                                            fontWeight: "bold",
                                            ":hover": {
                                                borderColor: "#6B3EFF",
                                                color: "#6B3EFF",
                                            },
                                            height: "50px",
                                        }}
                                        to={"/register"}
                                        size="large"
                                        variant="outlined"
                                    >
                                        Criar uma conta
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </Card>
                </Box>
            </Box>
        </Box>
    );
};
export default Login;
